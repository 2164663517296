.swal-container {
    z-index: 9999 !important;
}

.swal-popup {
    border-radius: 10px !important;
    opacity: 1 !important;
    box-shadow: 0px 0px 16px #124C621A !important;
}

.swal-title {
    padding: 0 !important;
    font-family: 'Montserrat Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.btn-confirm {
    font-size: 14px !important;
    font-family: 'Montserrat Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    border-radius: 12px !important;
    text-transform: none !important;
    padding: 8px 33px !important;
    margin: 5px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important;
    background-image: none !important;
}

.btn-confirm:hover:enabled {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important;
    transition: box-shadow 0.1s ease-in-out !important;
}

.btn-confirm:disabled {
    opacity: 0.5 !important;
}

.btn-success {
    background-color: #2AB6E5 !important;
}

.btn-danger {
    background-color: #F70931 !important;
}