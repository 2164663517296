
$fc-calendar-height: 650px;
$fc-calendar-height: 600px;
$fc-sidebar-width: 18rem;
$fc-event-font-weight: 600;
$fc-event-font-size: 0.8rem;
$fc-event-padding-x: 0.25rem;
$fc-event-padding-y: 0.25rem;
$fc-toolbar-btn-padding: 0.438rem 0.5rem;
$fc-day-toggle-btn-padding: 0.55rem 1.5rem;
$body-color: grey;
$body-bg: #ECE9F1;
$primary:#0595F0;
$border-color:#cdcdd4;
$text-muted:#cdcdd4;
$headings-color:#0595F0;
$btn-border-radius: 12px;
$card-border-radius: 12px 12px;
$card-bg: white;
$border-radius-sm: 12px;
$box-shadow: 0px 15px 28px #67A7BE29;


// Full calendar styles
.fc {
  // Toolbar
  text-transform: capitalize;
  font-family: 'Montserrat Bold';
  color: #0595F0;
  .fc-popover-title {
    text-transform: none !important;
  }
  .fc-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    .fc-prev-button,
    .fc-next-button {
      display: inline-block;
      background-color: transparent;
      border-color: transparent;
      .fc-icon {
        color: $body-color;
        font-size: 2.5em;
      }
      &:hover,
      &:active,
      &:focus {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
    .fc-prev-button {
      padding-left: 0 !important;
    }
    .fc-toolbar-chunk:first-child {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .fc-button {
      padding: $fc-toolbar-btn-padding;
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
    .fc-button-group {
      .fc-button {
        text-transform: capitalize;
        &:focus {
          box-shadow: none;
        }
      }
      .fc-button-primary {
        &:not(.fc-prev-button):not(.fc-next-button) {
          // background-color: rgba($primary, 0.1);
          background-color: transparent;
          border-color: $primary;
          color: $primary;
          &.fc-button-active,
          &:hover {
            background-color: rgba($color: $primary, $alpha: 0.2) !important;
            border-color: $primary !important;
            color: $primary;
          }
        }
        &.fc-sidebarToggle-button {
          border: 0;

          i,
          svg {
            height: 21px;
            width: 21px;
            font-size: 21px;
          }
        }
      }
      .fc-sidebarToggle-button {
        padding-left: 0;
        background-color: transparent !important;
        color: $body-color !important;

        &:not(.fc-prev-button):not(.fc-next-button):hover {
          background-color: transparent !important;
        }

        & + div {
          margin-left: 0;
        }
      }
      .fc-dayGridMonth-button,
      .fc-timeGridWeek-button,
      .fc-timeGridDay-button,
      .fc-listMonth-button {
        padding: $fc-day-toggle-btn-padding;

        &:last-child,
        &:first-child {
          border-radius: $btn-border-radius;
        }
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    & > * > :not(:first-child) {
      margin-left: 0rem;
    }
    
    .fc--button:empty,
    .fc-toolbar-chunk:empty {
      display: none;
    }
  }

  // Calendar head & body common
  tbody td,
  thead th {
    border-color: $border-color;
    &.fc-col-header-cell {
      border-right: 0;
      border-left: 0;
    }
  }

  .fc-view-harness {
    min-height: $fc-calendar-height;
    min-width: $fc-calendar-height;
  }

  .fc-daygrid-event-harness {
    .fc-event {
      font-size: $fc-event-font-size;
      font-weight: $fc-event-font-weight;
      padding: $fc-event-padding-y $fc-event-padding-x;
      background-color: #E8FAFD;
      border: 1px solid #D6F5FA;
      cursor: pointer;
      transition: all 0.3s ease;
      display: block;
      text-align: left;
    }
    .fc-event:hover {
     background-color: #a9dded;
     z-index: 10 !important;
    }
    & + .fc-daygrid-event-harness {
      margin-top: 0.3rem;
    }
    .fc-event-title{
      color: #0595F0;
      font-size: 10px;
      font-family: 'Montserrat ExtraBold';
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .fc-event-time{
      color: #0D2C43;
      font-size: 10px;
      font-family: 'Montserrat ExtraBold';
      text-overflow: ellipsis;

    }
  }
  .fc-daygrid-day-bottom {
    margin-top: 0.3rem;
  }
  .fc-daygrid-day {
    padding: 5px;
    .fc-daygrid-day-top {
      flex-direction: row;

    }
  }

  .fc-scroller-liquid-absolute{
    overflow-x: hidden !important;
  }
  .fc-col-header {
    overflow: hidden !important;
  }
  .fc-daygrid-event-dot{
    display: none !important;
  }

  // All Views Event
  .fc-daygrid-day-number,
  .fc-timegrid-slot-label-cushion,
  .fc-list-event-time {
    color: $body-color;
  }
  .fc-day-today {
    background: $body-bg !important;
    background-color: $body-bg !important;
  }

  // Timegrid
  .fc-timegrid {
    .fc-scrollgrid-section {
      .fc-col-header-cell,
      .fc-timegrid-axis {
        border-color: $border-color;
        border-left: 0;
        border-right: 0;
      }
      .fc-timegrid-axis {
        border-color: $border-color;
      }
    }
    .fc-timegrid-axis {
      &.fc-scrollgrid-shrink {
        .fc-timegrid-axis-cushion {
          text-transform: capitalize;
          color: $text-muted;
        }
      }
    }
    .fc-timegrid-slots {
      .fc-timegrid-slot {
        height: 3rem;
        .fc-timegrid-slot-label-frame {
          text-align: center;
          .fc-timegrid-slot-label-cushion {
            text-transform: uppercase;
          }
        }
      }
    }
    .fc-timegrid-divider {
      display: none;
    }
  }

  // List View
  .fc-list {
    border-color: $border-color;
    .fc-list-day-cushion {
      background: $body-bg;
    }
    .fc-list-event {
      &:hover {
        td {
          background-color: $body-bg;
        }
      }
      td {
        border-color: $border-color;
      }
    }
  }
}

// App Calendar specific
.app-calendar {

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }
  .fc-toolbar {
    h2 {
      font-size: 1.45rem;
    }
  }
  .fc-header-toolbar {
    margin-bottom: 0rem !important;
  }
  .fc-view-harness {
    margin: 0 0;
  }
  .fc-scrollgrid {
    border-color: $border-color;
  }
  .fc-day-past,
  .fc-day-future {
    .fc-daygrid-day-number {
      color: $text-muted;
    }
  }
  .fc-popover {
    box-shadow: $box-shadow;
    .fc-popover-header {
      background: transparent;
      padding: 0.5rem;
      .fc-popover-title,
      .fc-popover-close {
        color: $headings-color;
      }
    }
    .fc-popover-body {
      *:not(:last-of-type) {
        margin-bottom: 0.3rem;
      }
    }
  }

  .fc {
    .fc-event {
      .fc-event-main {
        color: #D6F5FA;
      }
    }
  }
  .fc-list-event {
    background: transparent !important;
  }

  @media (min-width: 650px) {
    .fc-toolbar-title {
      margin-left: 2rem !important;
    }
  }

 
  th {
    background-color: #F8FBFF;
  } 
}

