@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nutmeg Regular';
  src: url(./assets/fonts/Nutmeg/Nutmeg-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nutmeg Bold';
  src: url(./assets/fonts/Nutmeg/Nutmeg-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nutmeg ExtraBold';
  src: url(./assets/fonts/Nutmeg/Nutmeg-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Nutmeg Black';
  src: url(./assets/fonts/Nutmeg/Nutmeg-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow Regular';
  src: url(./assets/fonts/Barlow-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow Medium';
  src: url(./assets/fonts/Barlow-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow SemiBold';
  src: url(./assets/fonts/Barlow-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow Bold';
  src: url(./assets/fonts/Barlow-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat ExtraBold';
  src: url(./assets/fonts/Montserrat-ExtraBold.ttf) format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.accordion-info .MuiPaper-root.MuiAccordion-root:before {
  opacity: 0 !important;  
}

.accordion-info .MuiButtonBase-root.MuiAccordionSummary-root {
  flex-direction: row-reverse !important;
}